<template>
  <div class="mt-n6">
    <checkbox
      :fieldAttributes="{ 'hide-details': true, name: fieldAttributes.name }"
      type="select"
      :fieldAttrInput="{}"
      v-model="localValue"
      :result="result"
    ></checkbox>
  </div>
</template>
<script>
import checkbox from "@/commonComponents/checkbox.vue";
export default {
  components: { checkbox },
  props: ["result", "fieldAttributes","value"],
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set: function (newValue, newText) {
        //console.log(this, newValue);
        this.$emit("input", newValue, newText);
      },
    },
  },
};
</script>